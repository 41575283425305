'use client';

import styles from './Loading.module.css';
import { LOADING_TAGS } from './Loading.constants';


export const Loading = () => {
  const tags = LOADING_TAGS;
  const randomTag = tags.length ? tags[Math.floor(Math.random() * tags.length)] : undefined;

  return (
    <div className={styles.wrapper}>
      {randomTag && <div className={styles.tag}>{randomTag}</div>}
      <div className={styles.loader}>
        {[...Array(5)].map((_, index) => (
          <div key={index} className={styles.dot} style={{ '--index': `${index}` }} />
        ))}
      </div>
    </div>
  );
};
