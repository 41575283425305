export const LOADING_TAGS = [
  'Smoothing out the vinyl',
  'Calibrating the laser',
  'Mixing the perfect ink',
  'Firing up the printer',
  'Aligning the letters',
  'Cutting out the shapes',
  'Pressing the designs',
  'Polishing the details',
  'Perfecting the curves',
  'Illuminating the letters',
  'Leaving our mark',

  'Rolling out the vinyl',
  'Firing up the laser cutter',
  'Applying the finishing touches',
  'Carving out the details',
  'Perfecting the pressure',
  'Letting the ink dry',
];
